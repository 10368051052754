import React from "react";
import t from "../../../utilities/transitions";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { payment_success, change_user_details } from "../../../redux/actions";
import axios from "axios";
import Spinner from "../../../components/Spinner";

class JizzerPayment extends React.Component {
  constructor() {
    super();
    this.state = {
      working: true,
    };
  }

  componentDidMount() {
    this.props.resetAnimations();
    axios
      .get("/jizzer/jizzer-purchase")
      .then((res) => this.props.change_user_details(res.data.user))
      .catch((err) => alert("error", err));
  }

  componentDidUpdate(prevProps) {
    if (this.props.userInfo._id) {
      if (
        !prevProps.userInfo.instances.find((i) => i.service === "jizzer") &&
        this.props.userInfo.instances.find((i) => i.service === "jizzer")
      )
        this.props.payment_success({
          icon: <i className="fas fa-check-circle text-success me-2" />,
          text: "Payment Successful",
          instance: "jizzer",
        });
    }
  }

  render() {
    return (
      <motion.div
        className="pt-4"
        transition={t.transition}
        initial={this.props.enter}
        animate={t.normalize}
        exit={this.props.exit}
      >
        <MDBContainer>
          <MDBBtn
            color="link"
            style={{
              color: "#1976d2",
            }}
            rippleColor="#1976d2"
            size="lg"
            onClick={() => this.props.changePage("jizzer-terms")}
          >
            <i className="fas fa-chevron-left me-2" />
            Terms and Conditions
          </MDBBtn>
          <h5 className="text-center display-6 m-0">Payment</h5>
          <hr />
          <h5 className="text-center">Placeholder</h5>
          <MDBBtn
            disabled={this.state.working}
            color="success"
            className="d-block ms-auto"
          >
            {this.state.working ? (
              <>
                <Spinner size="sm" className="me-2" />
                Submitting
              </>
            ) : (
              <>
                <i className="fas fa-paper-plane me-2" />
                Submit
              </>
            )}
          </MDBBtn>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  payment_success,
  change_user_details,
})(JizzerPayment);
