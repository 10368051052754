import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";

class DNSModal extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Use Your Own Domain</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <h5 className="text-center mb-0">
                    In order to use your own domain, follow these steps.
                  </h5>
                  <small className="d-block mx-auto text-center fst-italic">
                    For security reasons, custom domains must connect from
                    Cloudflare servers.
                  </small>
                  <hr />
                  <h5 className="display-6 fs-3 text-black">
                    Step 1: Add your domain to Cloudflare
                  </h5>
                  <iframe
                    width="560"
                    height="315"
                    className="mx-auto max-w-100"
                    src="https://www.youtube.com/embed/Zq6PdhwHfZ0?si=wuG4VQqZ2BnnhdEd"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                  <hr />
                  <h5 className="display-6 fs-3 text-black">
                    Step 2: Point your website to the Frenworld server at
                    44.204.233.116
                  </h5>
                  <img
                    src="/assets/images/cloudflare.png"
                    alt="Cloudflare instructions"
                    className="w-100"
                  />
                  <hr />
                  <small className="d-block mx-auto text-center fst-italic">
                    Note: If you receive SSL errors, most likely your SSL
                    certificate has not been issued yet by Cloudflare, or your
                    DNS changes are still being propagated.
                  </small>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    className="bg-gray"
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(DNSModal);
