import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import h from "../../../../../utilities/helpers";
import t from "../../../../../utilities/transitions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBRipple,
  MDBTooltip,
  MDBBadge,
  MDBBtn,
} from "mdb-react-ui-kit";
import logos from "./formData/logos";
import files from "./formData/files";
import Spinner from "../../../../../components/Spinner";
import axios from "axios";
import DNSModal from "./DNSModal";

const Logos = ({
  animations,
  changeHandler,
  clearErrorPage,
  errorPage,
  formValues,
  formsSubmitted,
  screenDimensions,
  selectFile,
  view,
}) => {
  const domain = formValues.find((v) => v.field === "domain");
  const use_own_domain =
    formValues.find((v) => v.field === "use_own_domain")?.value || false;
  const domain_taken =
    formValues.find((v) => v.field === "domain_taken")?.value || "";

  const [scrolled, setScrolled] = useState(false);
  const [domainCheckTimeout, setDomainCheckTimeout] = useState(false);
  const [dnsModalShown, setDnsModalShown] = useState(false);

  useEffect(() => {
    const errors = formValues.filter((v) => v.tab === "logos" && v.error);
    errors.forEach((error) => {
      const element = document.getElementById(error.field);
      if (element) element.setCustomValidity(error.error);
    });
  }, []);

  useEffect(() => {
    h.hideToolTips();
  }, [dnsModalShown]);

  useEffect(() => {}, [use_own_domain]);

  const checkDomain = () => {
    axios
      .post("/dashboard/check-domain", {
        domain: domain.value,
        use_own_domain,
      })
      .then((res) => {
        changeHandler({
          target: {
            name: "domain_taken",
            value: res.data.domainTaken ? "taken" : "available",
          },
        });
      })
      .catch((err) => {
        console.log("checkDomain error", err);
        changeHandler({
          target: {
            name: "domain_taken",
            value: "",
          },
        });
      })
      .finally(() => {
        if (!domain.value) {
          changeHandler({
            target: {
              name: "domain_taken",
              value: "",
            },
          });
        }
      });
  };

  useEffect(() => {
    clearTimeout(domainCheckTimeout);
    if (
      domain.value &&
      !domain.error &&
      (!use_own_domain || domain.value.includes("."))
    ) {
      changeHandler({
        target: {
          name: "domain_taken",
          value: "checking",
        },
      });
      setDomainCheckTimeout(setTimeout(checkDomain, 1000));
    } else
      changeHandler({
        target: {
          name: "domain_taken",
          value: "",
        },
      });
  }, [domain.value, use_own_domain]);

  const scrollToError = () => {
    if (!scrolled && errorPage && view === "logos") {
      const errors = formValues.filter((v) => v.tab === "logos" && v.error);
      for (let e = 0; e < errors.length; e++) {
        const error = errors[e];
        const element = document.getElementById(error.field);
        if (element) element.scrollIntoView();
        clearErrorPage();
        setScrolled(true);
        break;
      }
    }
  };

  let domainCheckIcon;

  switch (domain_taken) {
    case "checking":
      domainCheckIcon = (
        <MDBTooltip
          tag="div"
          wrapperProps={{
            className: "trailing",
          }}
          title="Checking Availability"
        >
          <Spinner size="sm" color="primary" className="trailing" />
        </MDBTooltip>
      );
      break;
    case "taken":
      domainCheckIcon = (
        <MDBTooltip
          tag="i"
          wrapperProps={{
            className: "fas fa-times-circle trailing text-danger",
          }}
          title="Domain Unavailable"
        ></MDBTooltip>
      );
      break;
    case "available":
      domainCheckIcon = (
        <MDBTooltip
          tag="i"
          wrapperProps={{
            className: "trailing fas fa-check-circle text-success",
          }}
          title="Domain Available"
        ></MDBTooltip>
      );
      break;
    default:
      domainCheckIcon = <></>;
  }

  const toggleDnsModal = () => setDnsModalShown(!dnsModalShown);

  return (
    <motion.div
      transition={t.transition}
      initial={animations.enter}
      animate={t.normalize}
      exit={animations.exit}
      className="py-4"
      onAnimationComplete={scrollToError}
    >
      <DNSModal modalShown={dnsModalShown} toggleShowModal={toggleDnsModal} />
      <MDBValidation
        className={formsSubmitted.includes("logos") ? "was-validated" : ""}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0 fs-5 display-6">Domain Name</h6>
          <div
            className={
              screenDimensions.width > 755 ? "d-flex align-items-center" : ""
            }
          >
            <div
              className={
                screenDimensions.width > 755
                  ? "me-2"
                  : "d-flex justify-content-between align-items-center mb-2"
              }
            >
              <MDBBtn
                color="link"
                rippleColor="primary"
                className="min-w-max-content"
                type="button"
                onClick={() =>
                  changeHandler({
                    target: {
                      name: "use_own_domain",
                      value: Boolean(!use_own_domain),
                    },
                  })
                }
              >
                {use_own_domain ? "Use Frenworld Domain" : "Use Your Own"}
              </MDBBtn>
              {screenDimensions.width <= 755 ? (
                <i
                  className={`fas fa-info-circle ms-2 text-primary cursor-pointer transition-33 opacity-${
                    use_own_domain ? "100" : "0"
                  }`}
                  onClick={toggleDnsModal}
                  onKeyDown={toggleDnsModal}
                ></i>
              ) : (
                <></>
              )}
            </div>
            <MDBValidationItem feedback={domain.error} invalid={true}>
              <MDBInput
                id="domain"
                name="domain"
                onChange={changeHandler}
                className={!domain.error ? "mb-0" : 0}
                value={domain.value}
              >
                {domainCheckIcon}
              </MDBInput>
            </MDBValidationItem>

            <div className="h-max-content w-max-content">
              {screenDimensions.width > 755 ? (
                <MDBTooltip
                  tag="i"
                  wrapperProps={{
                    className: `fas fa-info-circle ms-2 text-primary cursor-pointer transition-33 opacity-${
                      use_own_domain ? "100" : "0"
                    }`,
                    onClick: toggleDnsModal,
                    onKeyDown: toggleDnsModal,
                  }}
                  title="DNS Instructions"
                ></MDBTooltip>
              ) : (
                <></>
              )}
              <div
                className={`transition-33 opacity-${
                  use_own_domain ? "0" : "100"
                }`}
              >
                .frenworld.net
              </div>
            </div>
          </div>
        </div>
        <hr />
        {logos
          .filter((v) => !v.id.includes("domain"))
          .map((item) => {
            const data = formValues.find((v) => v.field === item.id);
            return (
              <React.Fragment key={item.id}>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="m-0 fs-5 display-6">{item.label}</h6>
                  <MDBValidationItem
                    feedback={data.error}
                    invalid={true}
                    className="max-w-50"
                  >
                    <MDBInput
                      id={item.id}
                      name={item.id}
                      onChange={changeHandler}
                      className={!data.error ? "mb-0" : 0}
                      value={data.value}
                    />
                  </MDBValidationItem>
                </div>
                <hr />
              </React.Fragment>
            );
          })}
        {files
          .filter((file) => file.tab === "logos")
          .map((file) => {
            switch (file.field) {
              case "newJizzIcon":
                return (
                  <React.Fragment key={file.field}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="m-0 fs-5 display-6">{file.label}</h6>
                      <div className="d-flex justify-content-center align-items-center">
                        <MDBTooltip
                          wrapperProps={{
                            size: "lg",
                            floating: true,
                            className: "global-input-button p-2 square-7",
                            onClick: () => selectFile(file.field),
                          }}
                          title={`New ${
                            formValues.find(
                              (value) => value.field === "emission_name"
                            ).value
                          }`}
                          placement="left"
                        >
                          <div
                            style={{
                              backgroundImage: `url("${
                                formValues.find(
                                  (value) => value.field === file.field
                                ).path
                              }")`,
                            }}
                            className="fit-images"
                          ></div>
                        </MDBTooltip>
                      </div>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              case "defaultAvatar":
                return (
                  <React.Fragment key={file.field}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="m-0 fs-5 display-6">{file.label}</h6>
                      <div className="d-flex justify-content-center align-items-center square-7">
                        <MDBRipple
                          onClick={() => selectFile(file.field)}
                          tag="div"
                          rippleColor="primary"
                          className="fit-images fit-round"
                          style={{
                            backgroundImage: `url("${
                              formValues.find(
                                (value) => value.field === file.field
                              ).path
                            }")`,
                            cursor: "pointer",
                            borderRadius: "50%",
                          }}
                        ></MDBRipple>
                      </div>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              case "chadminBadge":
              case "jannyBadge":
              case "verifiedBadge":
                let flavor = "";
                const role = file.field.split("Badge")[0];
                switch (role) {
                  case "chadmin":
                    flavor = "admin";
                    break;
                  case "janny":
                    flavor = "mod";
                    break;
                  case "verified":
                    flavor = "verified";
                    break;
                  default:
                    console.log("oob role badge", role, file.field);
                }
                return (
                  <React.Fragment key={file.field}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="m-0 fs-5 display-6">{file.label}</h6>
                      <MDBRipple
                        onClick={() => selectFile(file.field)}
                        tag="div"
                        rippleColor="light"
                        className="h-100"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <MDBTooltip
                          tag="span"
                          wrapperProps={{
                            className: `name-${role} d-inline-block`,
                          }}
                          title={
                            (
                              formValues.find(
                                (v) => v.field === flavor + "_name"
                              ) || { value: "Verified" }
                            ).value
                          }
                        >
                          <MDBBadge className={`badge-${role} no-route`}>
                            <div className="d-flex justify-content-center align-items-center square-7 h-100">
                              <div
                                className="fit-images no-route"
                                style={{
                                  backgroundImage: `url("${
                                    formValues.find(
                                      (value) => value.field === file.field
                                    ).path
                                  }")`,
                                }}
                              ></div>
                            </div>
                          </MDBBadge>
                        </MDBTooltip>
                      </MDBRipple>
                    </div>
                    {role !== "verified" ? <hr /> : <></>}
                  </React.Fragment>
                );
              case "defaultBackground":
                return (
                  <React.Fragment key={file.field}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="m-0 fs-5 display-6">{file.label}</h6>
                      <div className="d-flex justify-content-center align-items-center square-15">
                        <MDBRipple
                          onClick={() => selectFile(file.field)}
                          tag="div"
                          rippleColor="light"
                          className="fit-images rounded-3"
                          style={{
                            backgroundImage: `url("${
                              formValues.find(
                                (value) => value.field === file.field
                              ).path
                            }")`,
                            cursor: "pointer",
                          }}
                        ></MDBRipple>
                      </div>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              default:
                return (
                  <React.Fragment key={file.field}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="m-0 fs-5 display-6">{file.label}</h6>
                      <div className="d-flex justify-content-center align-items-center square-7">
                        <MDBRipple
                          onClick={() => selectFile(file.field)}
                          tag="div"
                          rippleColor="primary"
                          className="fit-images rounded-3"
                          style={{
                            backgroundImage: `url("${
                              formValues.find(
                                (value) => value.field === file.field
                              ).path
                            }")`,
                            cursor: "pointer",
                          }}
                        ></MDBRipple>
                      </div>
                    </div>
                    <hr />
                  </React.Fragment>
                );
            }
          })}
      </MDBValidation>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
});

export default connect(mapStateToProps, {})(Logos);
