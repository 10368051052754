import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import axios from "axios";
import { login_schema } from "../utilities/validations";
import { set_user, set_verification_details, notify } from "../redux/actions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import Spinner from "../components/Spinner";
import { useHistory, useLocation } from "react-router-dom";

const Login = ({
  captchaReady,
  set_user,
  set_verification_details,
  tempAction,
  userInfo,
  verificationDetails,
}) => {
  const history = useHistory();
  const location = useLocation();

  /**
   * working: Boolean indicating whether the user is in the process of logging in
   * exit: framer-motion exit transition
   * inputs: Array - The input data (values, errors, etc)
   */
  const [working, setWorking] = useState(false);
  const [inputs, setInputs] = useState([
    {
      id: "username",
      error: "",
      invalid: true,
      value: "",
    },
    {
      id: "password",
      error: "",
      invalid: true,
      value: "",
    },
  ]);

  useEffect(() => {
    changeHandler({
      target: {
        name: "",
      },
    });

    return () => {
      if (userInfo.username && working)
        notify({
          icon: <i className="fas fa-user me-2 text-success"></i>,
          text: `Logged in as ${userInfo.username}`,
        });
    };
  }, []);

  useEffect(() => {
    if (userInfo.username) {
      if (tempAction?.page)
        history.push(tempAction.page, {
          exit: t.fade_out,
          enter: t.fade_out_right,
        });
      else history.push("/dashboard");
    }
  }, [userInfo.username]);

  useEffect(() => {
    if (verificationDetails) history.push("/validate-email");
  }, [verificationDetails]);

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  const getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Set user in application state
   */

  const submit = async () => {
    try {
      document.getElementById("login").classList.add("was-validated");
      let invalidInputs = inputs.filter((input) => input.invalid);
      invalidInputs.forEach((input) =>
        document
          .getElementById(input.id)
          .setCustomValidity(input.error || "Invalid")
      );
      if (!working && !invalidInputs.length) {
        setWorking(true);

        const data = Object.fromEntries(
          inputs.map((input) => [input.id, input.value.trim()])
        );
        login_schema.validateSync(data, {
          abortEarly: false,
        });
        const captchaKey = await getRecaptcha();
        data.captchaKey = captchaKey;
        axios
          .post("/auth/login", data)
          .then((res) => set_user(res.data.userInfo))
          .catch((err) => {
            setWorking(false);
            console.log(err.response);
            if (err.response) {
              switch (err.response.status) {
                case 401:
                  alert("Invalid username or password");
                  break;
                case 403:
                  alert(err.response.data.message);
                  break;
                case 423:
                  set_verification_details(err.response.data);
                  break;
                default:
                  alert("An error occurred. Please try again later");
              }
            } else alert("An error occurred. Please try again later");
          });
      }
    } catch (err) {
      setWorking(false);
      console.log("submit error", err);
      alert("An error occurred. Please try again later.");
    }
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */

  const changeHandler = (e) => {
    setInputs((curr) => {
      curr = curr.map((input) => {
        if (input.id === e.target.name)
          return {
            ...input,
            value: e.target.value,
          };
        else return input;
      });

      const data = Object.fromEntries(
        curr.map((input) => [input.id, input.value.trim()])
      );
      try {
        login_schema.validateSync(data, {
          abortEarly: false,
        });
        curr = curr.map((input) => {
          document.getElementById(input.id).setCustomValidity("");
          return {
            ...input,
            invalid: false,
            error: "",
          };
        });
      } catch (err) {
        let errorsAdded = [];
        curr = curr.map((input) => {
          if (
            err.inner.find((error) => error.path === input.id) &&
            errorsAdded.indexOf(input.id) === -1
          ) {
            errorsAdded.push(input.id);
            return {
              ...input,
              invalid: true,
              error: err.inner.find((error) => error.path === input.id).message,
            };
          } else
            return {
              ...input,
              invalid: false,
              error: "",
            };
        });
      }

      curr.forEach((input) => {
        if (input.invalid)
          document
            .getElementById(input.id)
            .setCustomValidity(input.error || "Invalid");
        else document.getElementById(input.id).setCustomValidity("");
      });

      return curr;
    });
  };

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  const pressEnter = (e) => {
    if (e.key === "Enter") submit();
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  const pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = inputs.find((f) => f.id === e.target.id);
      if (input) {
        const nextField = inputs[inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  h.floatLabels();

  return (
    <motion.div
      className="min-h-100 container py-4"
      transition={t.transition}
      exit={history?.location?.state?.exit || t.fade_out_scale_1}
      animate={t.normalize}
      initial={history?.location?.state?.enter || t.fade_out}
    >
      <h1 className="display-4 text-center">Login</h1>
      <hr></hr>
      <div className="form-containers">
        <MDBValidation
          name="login"
          method="dialog"
          id="login"
          onSubmit={submit}
        >
          <MDBValidationItem
            className="pb-4"
            feedback={inputs.find((input) => input.id === "username").error}
            invalid={true}
          >
            <MDBInput
              name="username"
              onChange={changeHandler}
              id="username"
              label="Username or Email"
              size="lg"
              className={
                !inputs.find((input) => input.id === "username").invalid
                  ? "mb-0"
                  : 0
              }
              onKeyPress={pressEnter}
              onKeyDown={pressTab}
            />
          </MDBValidationItem>
          <MDBValidationItem
            className="pb-4"
            feedback={inputs.find((input) => input.id === "password").error}
            invalid={true}
          >
            <MDBInput
              name="password"
              onChange={changeHandler}
              id="password"
              label="Password"
              size="lg"
              type="password"
              className={
                !inputs.find((input) => input.id === "password").invalid
                  ? "mb-0"
                  : 0
              }
              onKeyPress={pressEnter}
              onKeyDown={pressTab}
            />
          </MDBValidationItem>
        </MDBValidation>
        {working ? (
          <MDBBtn color="success" size="lg" className="w-100" block disabled>
            <Spinner size="sm" className="me-2" />
            Working
          </MDBBtn>
        ) : (
          <MDBBtn
            color="success"
            onClick={submit}
            size="lg"
            block
            className="w-100"
          >
            <i className="fas fa-paper-plane me-2"></i>Submit
          </MDBBtn>
        )}
        <MDBBtn
          onClick={() =>
            history.push("/forgot-password", {
              exit: t.fade_out_left,
              enter: t.fade_out_right,
            })
          }
          to="/forgot-password"
          style={{ backgroundColor: "#607D8B" }}
          size="lg"
          className="w-100 mt-4"
          block
        >
          Forgot Password<i className="fas fa-chevron-right ms-2"></i>
        </MDBBtn>
        <MDBBtn
          color="link"
          rippleColor="primary"
          className="text-unset my-4 fs-6 d-block mx-auto"
          onClick={() => history.push("/create-account")}
        >
          <i className="fas fa-user-plus me-2"></i>Create Account
        </MDBBtn>
      </div>
      <MDBContainer>
        <small className="mt-2 d-block mx-auto text-center">
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
          <a href="https://policies.google.com/terms"> Terms of Service</a>{" "}
          apply.
        </small>
      </MDBContainer>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    captchaReady: state.captchaReady,
    tempAction: state.tempAction,
    userInfo: state.userInfo,
    verificationDetails: state.verificationDetails,
  };
};

export default connect(mapStateToProps, {
  set_user,
  set_verification_details,
  notify,
})(Login);
