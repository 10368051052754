import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import Menu from "./dashboard/Menu";
import MenuMobile from "./dashboard/MenuMobile";
import options from "./dashboard/options";
import {
  select_dashboard_item,
  set_dashboard_data,
  terminate_dashboard,
} from "../redux/actions";
import { MDBTabsContent, MDBTabsPane, MDBContainer } from "mdb-react-ui-kit";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";

/**
 * This is the user dashboard
 */

const Dashboard = ({
  dashboardItemSelected,
  set_dashboard_data,
  terminate_dashboard,
  userInfo,
}) => {
  const [loaded, setLoaded] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    load();
    return terminate_dashboard;
  }, []);

  useEffect(() => {
    if (!userInfo?._id) history.push("/login");
  }, [userInfo?._id]);

  useEffect(() => {
    if (!loaded && userInfo.role) setLoaded(true);
  }, [userInfo.role]);

  const load = () => {
    if (h.checkChadmin(userInfo))
      axios
        .get("/api/dashboard/chadmin")
        .then((res) =>
          this.props.set_dashboard_data({
            userList: res.data.userList,
            contactFormMessages: res.data.contactFormMessages,
            statistics: res.data.statistics,
            releaseNotes: res.data.releaseNotes,
            servers: res.data.servers,
            messageCounts: res.data.messageCounts,
          })
        )
        .catch((err) => {
          console.log("instances load error", err);
          setTimeout(load, 1000);
        });
    else if (h.checkJanny(userInfo))
      axios
        .get("/api/dashboard/janny")
        .then((res) =>
          set_dashboard_data({
            contactFormMessages: res.data.contactFormMessages,
            statistics: res.data.statistics,
            releaseNotes: res.data.releaseNotes,
          })
        )
        .catch((err) => {
          console.log("instances load error", err);
          setTimeout(load, 1000);
        });
    else
      axios
        .get("/api/dashboard/child")
        .then((res) =>
          set_dashboard_data({
            contactFormMessages: res.data.contactFormMessages,
            statistics: res.data.statistics,
            releaseNotes: res.data.releaseNotes,
          })
        )
        .catch((err) => {
          console.log("instances load error", err);
          setTimeout(load, 1000);
        });
  };

  return (
    <>
      <motion.div
        className="h-100"
        transition={t.transition}
        exit={history?.location?.state?.exit || t.fade_out_scale_1}
        animate={t.normalize}
        initial={
          history?.location?.state?.enter ||
          (dashboardItemSelected === "instances" ? t.fade_out_left : t.fade_out)
        }
      >
        <MDBContainer fluid className="h-100 px-0">
          <div className="row mx-0 h-100">
            {loaded ? (
              <div className="h-lg-100 col-12 col-lg-3 py-3 px-2">
                <div className="dashboard-menu-desktop h-100">
                  <Menu />
                </div>
                <div className="dashboard-menu-mobile">
                  <MenuMobile />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="col-12 col-lg-9 h-100 px-0">
              <div className="tab-content h-100" id="pills-tabContent">
                {userInfo.role && loaded ? (
                  <MDBTabsContent className="h-100">
                    {options
                      .find((o) => o.category === userInfo.role)
                      .options.map((option) => (
                        <MDBTabsPane
                          className="h-100"
                          open={option.id === dashboardItemSelected}
                        >
                          <option.component />
                        </MDBTabsPane>
                      ))}
                  </MDBTabsContent>
                ) : (
                  <MDBContainer className="pt-5">
                    <LinearProgress />
                  </MDBContainer>
                )}
              </div>
            </div>
          </div>
        </MDBContainer>
      </motion.div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardItemSelected: state.dashboardItemSelected,
    userInfo: state.userInfo,
  };
};

export default connect(mapStateToProps, {
  select_dashboard_item,
  set_dashboard_data,
  terminate_dashboard,
})(Dashboard);
