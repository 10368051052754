import React from "react";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const Nav = ({ view, nav, screenDimensions }) => (
  <>
    <Tabs
      value={view}
      onChange={(e, tab) => nav(tab)}
      orientation={screenDimensions.width < 675 ? "vertical" : "horizontal"}
      className={screenDimensions.width < 675 ? "w-max-content mx-auto" : ""}
    >
      <Tab
        style={{ minHeight: "48px" }}
        icon={
          <i
            style={{ color: "var(--mdb-success)" }}
            className="fas fa-globe-americas"
          ></i>
        }
        iconPosition="start"
        label="Name and Icons"
        value="logos"
      />
      <Tab
        style={{ minHeight: "48px" }}
        icon={
          <i style={{ color: "#FFAB00" }} className="fas fa-quote-right"></i>
        }
        iconPosition="start"
        label="Verbiage"
        value="verbiage"
      />
      <Tab
        style={{ minHeight: "48px" }}
        icon={
          <i
            style={{ color: "var(--mdb-danger)" }}
            className="fas fa-palette"
          ></i>
        }
        iconPosition="start"
        label="Appearance"
        value="colors"
      />
      <Tab
        style={{ minHeight: "48px" }}
        icon={
          <i style={{ color: "var(--mdb-indigo)" }} className="fas fa-cog"></i>
        }
        iconPosition="start"
        label="App Settings"
        value="settings"
      />
    </Tabs>
    {screenDimensions.width < 675 ? <hr /> : <></>}
  </>
);

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
});

export default connect(mapStateToProps, {})(Nav);
