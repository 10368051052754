export default [
  {
    id: "max_emission_chars",
    label: "Max Post Characters",
    type: "number",
    tab: "settings",
    defaultValue: 300,
  },
  {
    id: "max_file_count",
    label: "Max Files Per Post",
    type: "number",
    tab: "settings",
    defaultValue: 4,
  },
  {
    id: "max_individual_file_size",
    label: "Max Individual File Size (MB)",
    type: "number",
    tab: "settings",
    defaultValue: 2,
  },
  {
    id: "max_total_file_size",
    label: "Max Total File Size (MB)",
    type: "number",
    tab: "settings",
    defaultValue: 8,
  },
  {
    id: "imagesAllowed",
    label: "Image Uploads Allowed",
    type: "boolean",
    tab: "settings",
    defaultValue: true,
  },
  {
    id: "audioAllowed",
    label: "Audio Uploads Allowed",
    type: "boolean",
    tab: "settings",
    defaultValue: true,
  },
  {
    id: "videoAllowed",
    label: "Video Uploads Allowed",
    type: "boolean",
    tab: "settings",
    defaultValue: true,
  },
  {
    id: "email_verification_required",
    label: "Email Verification Required",
    type: "boolean",
    tab: "settings",
    defaultValue: false,
  },
  {
    id: "streaming_enabled",
    label: "Streaming Enabled",
    type: "boolean",
    tab: "settings",
    defaultValue: true,
  },
  {
    id: "streaming_storage_enabled",
    label: "Streaming Storage Enabled",
    type: "boolean",
    tab: "settings",
    defaultValue: true,
  },
  {
    id: "streaming_verification_required",
    label: "Stream Verification Required",
    type: "boolean",
    tab: "settings",
    defaultValue: false,
  },
  {
    id: "require_approval",
    label: "Require Approval",
    type: "boolean",
    tab: "settings",
    defaultValue: false,
  },
  {
    id: "virgil_chad_allowed",
    label: "Virgil/Chad AI Enabled",
    type: "boolean",
    tab: "settings",
    defaultValue: true,
  },
  {
    id: "liveStreamingAllowed",
    label: "Livestreaming Allowed",
    type: "boolean",
    tab: "settings",
    defaultValue: true,
  },
  {
    id: "gigachad",
    label: "Superadmin User",
    type: "other",
    tab: "settings",
    defaultValue: true,
  },
  {
    id: "rules",
    label: "Rules",
    type: "other",
    tab: "settings",
    defaultValue: [],
  },
];
