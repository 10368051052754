import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import t from "../../../../../utilities/transitions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import verbiage from "./formData/verbiage";

const Verbiage = ({
  animations,
  changeHandler,
  clearErrorPage,
  errorPage,
  formValues,
  formsSubmitted,
  view,
}) => {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const errors = formValues.filter((v) => v.tab === "verbiage" && v.error);
    errors.forEach((error) => {
      const element = document.getElementById(error.field);
      if (element) element.setCustomValidity(error.error);
    });
  }, []);

  const scrollToError = () => {
    if (!scrolled && errorPage && view === "verbiage") {
      const errors = formValues.filter((v) => v.tab === "verbiage" && v.error);
      for (let e = 0; e < errors.length; e++) {
        const error = errors[e];
        const element = document.getElementById(error.field);
        if (element) element.scrollIntoView();
        clearErrorPage();
        setScrolled(true);
        break;
      }
    }
  };
  return (
    <motion.div
      transition={t.transition}
      initial={animations.enter}
      animate={t.normalize}
      exit={animations.exit}
      onAnimationComplete={scrollToError}
      className="pb-4"
    >
      <MDBValidation
        className={formsSubmitted.includes("verbiage") ? "was-validated" : ""}
      >
        <MDBListGroup light>
          {verbiage.map((item) => {
            const data = formValues.find((v) => v.field === item.id);
            return (
              <MDBListGroupItem
                key={item.id}
                className="d-flex justify-content-between align-items-center"
              >
                <h6 className="m-0 fs-5 display-6">{item.label}</h6>
                <MDBValidationItem
                  feedback={data.error}
                  invalid={true}
                  className="max-w-50"
                >
                  <MDBInput
                    id={item.id}
                    name={item.id}
                    onChange={changeHandler}
                    className={!data.error ? "mb-0" : 0}
                    value={data.value}
                  />
                </MDBValidationItem>
              </MDBListGroupItem>
            );
          })}
        </MDBListGroup>
      </MDBValidation>
    </motion.div>
  );
};

export default Verbiage;
