export default [
  {
    id: "domain",
    label: "Domain",
    type: "text",
    tab: "logos",
    defaultValue: "",
  },
  {
    id: "use_own_domain",
    label: "Use Own Domain",
    type: "boolean",
    tab: "logos",
    defaultValue: false,
  },
  {
    id: "domain_taken",
    label: "Domain Taken",
    type: "string",
    tab: "logos",
    defaultValue: "",
  },
  {
    id: "app_name",
    label: "App Name",
    type: "text",
    tab: "logos",
    defaultValue: "Frenworld",
  },
  {
    id: "description",
    label: "Description",
    type: "text",
    tab: "logos",
    defaultValue: "Welcome to Frenworld!",
  },
];
