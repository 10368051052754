import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";

class SurveyAnswersModal extends React.Component {
  constructor() {
    super();
    this.state = {
      working: false,
    };
  }

  render() {
    const request = this.props.user
      ? this.props.user.instances.find((i) => i.id === "jizzer-beta")
      : false;
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              {this.props.user ? (
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>
                      Survey Answers for {this.props.user.username}
                    </MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <h5 className="m-0">Briefly Tell us About Yourself</h5>
                    <hr></hr>
                    <p>{request.requestInfo.about}</p>
                    <h5 className="mb-0 mt-4">
                      What's Your Plan for your Frenworld Instance?
                    </h5>
                    <hr></hr>
                    <p>{request.requestInfo.plan}</p>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      className="bg-gray"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              ) : (
                <></>
              )}
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(SurveyAnswersModal);
