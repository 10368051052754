export default [
  {
    id: "admin_name",
    label: "Admin Name",
    type: "text",
    tab: "verbiage",
    defaultValue: "Admin",
  },
  {
    id: "admin_plural",
    label: "Admin Plural",
    type: "text",
    tab: "verbiage",
    defaultValue: "Admins",
  },
  {
    id: "mod_name",
    label: "Mod Name",
    type: "text",
    tab: "verbiage",
    defaultValue: "Moderator",
  },
  {
    id: "mod_plural",
    label: "Mod Plural",
    type: "text",
    tab: "verbiage",
    defaultValue: "Moderators",
  },
  {
    id: "child_name",
    label: "Regular User Name",
    type: "text",
    tab: "verbiage",
    defaultValue: "Regular User",
  },
  {
    id: "child_plural",
    label: "Regular User Plural",
    type: "text",
    tab: "verbiage",
    defaultValue: "Regular Users",
  },
  {
    id: "emission_name",
    label: "Post Name",
    type: "text",
    tab: "verbiage",
    defaultValue: "Post",
  },
  {
    id: "emission_plural",
    label: "Post Plural",
    type: "text",
    tab: "verbiage",
    defaultValue: "Posts",
  },
  {
    id: "signalboost_name",
    label: "Signalboost Name",
    type: "text",
    tab: "verbiage",
    defaultValue: "Signalboost",
  },
  {
    id: "signalboost_current",
    label: "Signalboost Current",
    type: "text",
    tab: "verbiage",
    defaultValue: "Signalboosting",
  },
  {
    id: "signalboost_past",
    label: "Signalboost Past",
    type: "text",
    tab: "verbiage",
    defaultValue: "Signalboosted",
  },
  {
    id: "signalboost_plural",
    label: "Signalboost Plural",
    type: "text",
    tab: "verbiage",
    defaultValue: "Signalboosts",
  },
  {
    id: "signalboost_verb",
    label: "Signalboost verb",
    type: "text",
    tab: "verbiage",
    defaultValue: "Signalboost",
  },
  {
    id: "follow_name",
    label: "Follow Name",
    type: "text",
    tab: "verbiage",
    defaultValue: "Follower",
  },
  {
    id: "follow_current",
    label: "Follow Current",
    type: "text",
    tab: "verbiage",
    defaultValue: "Followering",
  },
  {
    id: "follow_plural",
    label: "Follow Plural",
    type: "text",
    tab: "verbiage",
    defaultValue: "Followers",
  },
  {
    id: "follow_verb",
    label: "Follow Verb",
    type: "text",
    tab: "verbiage",
    defaultValue: "Follow",
  },
  {
    id: "unfollow_current",
    label: "Unfollow Name",
    type: "text",
    tab: "verbiage",
    defaultValue: "Unfollow",
  },
  {
    id: "unfollow_verb",
    label: "Unfollow Verb",
    type: "text",
    tab: "verbiage",
    defaultValue: "Unfollowing",
  },
];
