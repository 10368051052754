import React from "react";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import h from "../../../../utilities/helpers";
import t from "../../../../utilities/transitions";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { set_server_status } from "../../../../redux/actions";
import JizzerView from "./JizzerView";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { StaticRouter, Switch, Route } from "react-router-dom";

class Jizzer extends React.Component {
  constructor() {
    super();
    this.state = {
      view: "prelaunch",
      status: [],
    };
  }

  componentDidMount() {
    if (this.props.socket) this.initializeSocket();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
  }

  componentWillUnmount() {
    this.props.socket.off("jizzer-launch-status");
    this.props.socket.off("jizzer-launch-file-inc");
  }

  initializeSocket = () => {
    if (!this.props.socket) return;
    this.props.socket.on("jizzer-launch-status", (status) => {
      status.id = uuid();
      this.setState((curr) => ({
        ...curr,
        status,
      }));
    });
    this.props.socket.on("jizzer-launch-file-inc", (total) => {
      this.setState((curr) => ({
        ...curr,
        status: curr.status.map((status) => {
          if (status.label.includes("Processing Files")) {
            status.total = total;
            if (!status.current) status.current = 0;
            status.current++;
            status.label = `Processing Files ${status.current}/${status.total}`;
          }
          return status;
        }),
      }));
    });
  };

  launch = () => {
    let bio = document.getElementById("input-bio");
    if (bio) {
      bio = h.sanitizeHTML(bio.innerHTML);
      if (h.checkHTMLLength(bio) > 1000) {
        this.setState((curr) => ({
          ...curr,
          errorPage: "settings",
        }));
        return;
      }
      if (typeof bio !== "string") bio = "";
    }

    const errors = this.state.formValues.filter((v) => v.error);
    console.log("errors", this.state.formValues);
    for (let e = 0; e < errors.length; e++) {
      const error = errors[e];
      if (error.field === "gigachad") {
        let errorFound = false;
        Object.keys(error.error).forEach((key) => {
          if (error.error[key]) {
            errorFound = true;
            this.setState((curr) => ({
              ...curr,
              errorPage: "settings",
            }));
          }
        });
        if (errorFound) return;
      } else {
        this.setState((curr) => ({
          ...curr,
          errorPage: error.tab,
        }));
        return;
      }
    }
    this.setState(
      (curr) => ({
        ...curr,
        view: "launching",
        status: [{ label: "Updating Preferences", status: "inProgress" }],
      }),
      () => {
        const fd = new FormData();
        this.state.formValues
          .filter((value) => !value.path)
          .forEach((value) => {
            if (value.field === "gigachad" && bio) {
              value.value.bio = bio;
            }
            fd.append(
              value.field,
              ["gigachad", "rules"].includes(value.field)
                ? JSON.stringify(value.value)
                : value.value
            );
          });
        this.state.formValues
          .filter((value) => value.path && value.file)
          .forEach((value) => fd.append(value.field, value.file, value.name));
        axios
          .post("/jizzer/jizzer-preferences", fd)
          .then((res) => {
            this.setState((curr) => ({
              ...curr,
              view: "live",
            }));
          })
          .catch((err) =>
            this.setState(
              (curr) => ({
                ...curr,
                view: "prelaunch",
              }),
              () => {
                console.log("error", err.response?.data?.error);
                alert(
                  err.response?.data?.error ||
                    "An error occurred. Please try again later."
                );
              }
            )
          );
      }
    );
  };

  setFormValues = (formValues) =>
    this.setState((curr) => ({
      ...curr,
      formValues,
    }));

  clearErrorPage = () =>
    this.setState((curr) => ({
      ...curr,
      errorPage: false,
    }));

  render() {
    return (
      <motion.div
        className="pt-4 h-100 d-flex flex-column"
        transition={t.transition}
        initial={t.fade_out_right}
        animate={t.normalize}
        exit={t.fade_out_right}
      >
        <MDBContainer fluid>
          <div className="row align-items-center">
            <div className="col-4">
              <MDBBtn
                color="link"
                className="text-primary"
                onClick={() => this.props.setView("list")}
                size={
                  this.props.screenDimensions.width < 478 ? "sm" : undefined
                }
                rippleColor="primary"
              >
                <i
                  className={`fas fa-chevron-left ${
                    this.props.screenDimensions.width >= 405 ? "me-2" : "mb-2"
                  }`}
                ></i>
                Products
              </MDBBtn>
            </div>
            <div className="col-4">
              <h5 className="text-center display-6 fs-3 mb-0">Frenworld</h5>
            </div>
            {this.state.view === "prelaunch" ? (
              <div className="col-4">
                <MDBBtn
                  className={`d-block ms-auto ${
                    this.props.screenDimensions.width >= 375
                      ? ""
                      : "text-success"
                  }`}
                  color={
                    this.props.screenDimensions.width >= 375
                      ? "success"
                      : "link"
                  }
                  size={
                    this.props.screenDimensions.width < 478 ? "sm" : undefined
                  }
                  onClick={this.launch}
                >
                  <i
                    className={`fas fa-rocket ${
                      this.props.screenDimensions.width >= 372 ? "me-2" : "mb-2"
                    }`}
                  />
                  Launch
                </MDBBtn>
              </div>
            ) : (
              <></>
            )}
          </div>
          <hr />
          <StaticRouter location={this.state.view}>
            <AnimatePresence exitBeforeEnter>
              <Switch key={this.state.view}>
                <Route exact path=":view">
                  <JizzerView
                    setFormValuesParent={this.setFormValues}
                    errorPage={this.state.errorPage}
                    clearErrorPage={this.clearErrorPage}
                    status={this.state.status}
                    view={this.state.view}
                    formValuesParent={this.state.formValues}
                  />
                </Route>
              </Switch>
            </AnimatePresence>
          </StaticRouter>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { set_server_status })(Jizzer);
