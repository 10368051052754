// Contains data for all form validations
import * as y from "yup";
import { parse as parseURL } from "url";
import h from "./helpers";

const validHostname =
  /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/;

function checkWhiteSpace(string) {
  return this.test("checkWhiteSpace", string, function (value) {
    const { path, createError } = this;
    if (!value.split("\n").join("").split(" ").join(""))
      return createError({ path, message: "Please enter a message" });
    return true;
  });
}

function noPluses(string) {
  return this.test("noPluses", string, function (value) {
    const { path, createError } = this;
    if (value.split("+").length > 1)
      return createError({ path, message: "Invalid characters detected" });
    return true;
  });
}

function validURL(string) {
  return this.test("validURL", string, function (value) {
    const { path, createError } = this;
    try {
      const parsed = parseURL(value);
      if (!parsed.protocol) value = "https://" + value;
      new URL(value);
      return true;
    } catch (err) {
      console.log("error", err);
      return createError({ path, message: "Please enter a valid url" });
    }
  });
}

function stringMax(limit) {
  return this.test("stringMax", limit, function (value) {
    const { path, createError } = this;
    if (!h.isNumeric(value))
      return createError({ path, message: "Please enter a valid number" });
    if (Number(value) > limit)
      return createError({ path, message: "Max value exceeded" });
    return true;
  });
}

function stringWholeNumber(limit) {
  return this.test("stringWholeNumber", limit, function (value) {
    const { path, createError } = this;
    if (!h.isNumeric(value))
      return createError({ path, message: "Please enter a valid number" });
    if (Number(value) % 1)
      return createError({ path, message: "Must be a whole number" });
    return true;
  });
}

function minifiedMax(limit) {
  return this.test("minifiedMax", limit, function (value) {
    const { path, createError } = this;
    if (value.split(" ").join("").split("\n").join("").length > limit)
      return createError({
        path,
        message: `Limit exceeded (Max: ${limit} chars)`,
      });
    return true;
  });
}

y.addMethod(y.string, "checkWhiteSpace", checkWhiteSpace);
y.addMethod(y.string, "noPluses", noPluses);
y.addMethod(y.string, "validURL", validURL);
y.addMethod(y.string, "stringMax", stringMax);
y.addMethod(y.string, "minifiedMax", minifiedMax);
y.addMethod(y.string, "stringWholeNumber", stringWholeNumber);

export const link_schema = y.object().shape({
  text: y.string().required("Please enter text"),
  link: y
    .string()
    .validURL("Please enter a valid URL")
    .required("Please enter a URL"),
});

export const user_schema = y.object().shape({
  username: y
    .string()
    .min(4, "Username is too short (min: 4 characters)")
    .max(30, "Username is too long (max: 30 characters)")
    .required("Please enter a username")
    .matches(/^[a-zA-Z0-9-_]+$/, "Username must be alphanumeric"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  password1: y
    .string()
    .min(4, "Password is too short (min: 4 characters)")
    .max(256, "Password is too long (max: 256 characters)")
    .required("Please enter a password"),
  password2: y
    .string()
    .oneOf([y.ref("password1"), null], "Passwords must match")
    .required("Please confirm your password"),
  displayName: y
    .string()
    .min(1, "Please enter a Display Name")
    .max(50, "Display Name is too long (max: 50 chars)")
    .required("Please enter a display name"),
  location: y.string().max(50, "Location is too long (max: 50 chars)"),
  website: y.string().max(100, "Website is too long (max: 100 chars)"),
});

export const disable_schema = y.object().shape({
  explanation: y
    .string()
    .min(1, "Please enter an explanation")
    .max(5000, "Explanation is too long (Max: 5000 chars)")
    .required("Please enter an explanation"),
});

export const general_feedback_schema = y.object().shape({
  app: y.string().required("lol"),
  name: y
    .string()
    .min(1, "Please enter your name")
    .max(100, "Name is too long (Max: 100 chars)")
    .required("Please enter your name"),
  subject: y
    .string()
    .min(1, "Please enter a subject")
    .max(100, "Subject is too long (Max: 100 chars)")
    .required("Please enter a subject"),
  feedback: y
    .string()
    .min(1, "Please enter your feedback")
    .max(10000, "Feedback is too long (Max: 10000 chars)")
    .required("Please enter your feedback"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
});

export const name_icon_schema = y.object().shape({
  app_name: y
    .string()
    .min(1, "Please enter an App Name")
    .max(20, "App name is too long (Max: 20 chars)")
    .required("Please enter an app name"),
  description: y
    .string()
    .min(1, "Please enter a description")
    .max(150, "Description is too long (Max: 150 chars)")
    .required("Please enter a description"),
  domain: y
    .string()
    .matches(validHostname, "Please enter a valid host name")
    .max(64, "Domain is too short (Max: 64 characters)")

    .required(),
  use_own_domain: y.boolean().optional(),
  domain_taken: y.string().optional(),
});

export const verbiage_schema = y.object().shape({
  emission_name: y
    .string()
    .min(1, "Please enter an emission name")
    .max(30, "Emission name is too long (Max: 30 chars)")
    .required("Please enter an emission name"),
  emission_plural: y
    .string()
    .min(1, "Please enter a plural emission name")
    .max(30, "Emission plural name is too long (Max: 30 chars)")
    .required("Please enter a plural emission name"),
  signalboost_name: y
    .string()
    .min(1, "Please enter a signalboost name")
    .max(30, "Signalboost name is too long (Max: 30 chars)")
    .required("Please enter a signalboost name"),
  signalboost_plural: y
    .string()
    .min(1, "Please enter a plural signalboost name")
    .max(30, "Plural signalboost name is too long (Max: 30 chars)")
    .required("Please enter a plural signalboost name"),
  signalboost_past: y
    .string()
    .min(1, "Please enter a past signalboost name")
    .max(30, "Past signalboost name is too long (Max: 30 chars)")
    .required("Please enter a past signalboost name"),
  signalboost_verb: y
    .string()
    .min(1, "Please enter signalboost verb")
    .max(30, "Signalboost verb is too long (Max: 30 chars)")
    .required("Please enter signalboost verb"),
  signalboost_current: y
    .string()
    .min(1, "Please enter signalboost current")
    .max(30, "Signalboost current is too long (Max: 30 chars)")
    .required("Please enter signalboost current"),
  follow_name: y
    .string()
    .min(1, "Please enter a follower name")
    .max(30, "Follower name is too long (Max: 30 chars)")
    .required("Please enter a follower name"),
  follow_plural: y
    .string()
    .min(1, "Please enter a follower plural name")
    .max(30, "Follower plural name is too long (Max: 30 chars)")
    .required("Please enter a follower plural name"),
  follow_verb: y
    .string()
    .min(1, "Please enter a follow verb")
    .max(30, "Follow verb is too long (Max: 30 chars)")
    .required("Please enter a follow verb"),
  follow_current: y
    .string()
    .min(1, "Please enter a follow current name")
    .max(30, "Follower current name is too long (Max: 30 chars)")
    .required("Please enter a follow current name"),
  unfollow_verb: y
    .string()
    .min(1, "Please enter a unfollow verb")
    .max(30, "Follow verb is too long (Max: 30 chars)")
    .required("Please enter a unfollow verb"),
  unfollow_current: y
    .string()
    .min(1, "Please enter a unfollow current name")
    .max(30, "Follower current name is too long (Max: 30 chars)")
    .required("Please enter a unfollow current name"),
  admin_name: y
    .string()
    .min(1, "Please enter an admin name")
    .max(30, "Admin name is too long (Max: 30 chars)")
    .required("Please enter an admin name"),
  admin_plural: y
    .string()
    .min(1, "Please enter an admin plural name")
    .max(30, "Admin Plural name is too long (Max: 30 chars)")
    .required("Please enter an admin plural name"),
  mod_name: y
    .string()
    .min(1, "Please enter a moderator name")
    .max(30, "Moderator name is too long (Max: 30 chars)")
    .required("Please enter a moderator name"),
  mod_plural: y
    .string()
    .min(1, "Please enter a plural moderator name")
    .max(30, "Plural moderator name is too long (Max: 30 chars)")
    .required("Please enter a plural moderator name"),
  child_name: y
    .string()
    .min(1, "Please enter a regular user name")
    .max(30, "Regular user name is too long (Max: 30 chars)")
    .required("Please enter a regular user name"),
  child_plural: y
    .string()
    .min(1, "Please enter a plural regular user name")
    .max(30, "Plural regular user name is too long (Max: 30 chars)")
    .required("Please enter a plural regular user name"),
});

export const appearance_schema = y.object().shape({
  custom_css: y
    .string()
    .minifiedMax(100000, "All themes CSS is too long (max: 100,000 chars)"),
  custom_css_default: y
    .string()
    .minifiedMax(100000, "Default Theme CSS is too long (max: 100,000 chars)"),
  custom_css_dark: y
    .string()
    .minifiedMax(100000, "Dark Theme CSS is too long (max: 100,000 chars)"),
  body_background_color_contrast_dark: y
    .string()
    .max(1000, "CSS is too long (max: 1000 chars)"),
  body_background_color_dark: y
    .string()
    .max(1000, "CSS is too long (max: 1000 chars)"),
  body_background_color_default: y
    .string()
    .max(1000, "CSS is too long (max: 1000 chars)"),
  body_text_color_dark: y
    .string()
    .max(1000, "CSS is too long (max: 1000 chars)"),
  body_text_color_default: y
    .string()
    .max(1000, "CSS is too long (max: 1000 chars)"),
  danger_dark: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  danger_default: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  dark_dark: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  dark_default: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  info_dark: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  info_default: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  light_dark: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  light_default: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  primary_dark: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  primary_default: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  secondary_dark: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  secondary_default: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  success_dark: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  success_default: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  warning_dark: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
  warning_default: y.string().max(1000, "CSS is too long (max: 1000 chars)"),
});

export const gigachad_schema = y.object().shape({
  username: y
    .string()
    .min(4, "Username is too short (min: 4 characters)")
    .max(30, "Username is too long (max: 30 characters)")
    .required("Please enter a username")
    .matches(/^[a-zA-Z0-9-_]+$/, "Username must be alphanumeric"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  password1: y
    .string()
    .min(4, "Password is too short (min: 4 characters)")
    .max(256, "Password is too long (max: 256 characters)")
    .required("Please enter a password"),
  password2: y
    .string()
    .oneOf([y.ref("password1"), null], "Passwords must match")
    .required("Please confirm your password"),
  displayName: y
    .string()
    .min(1, "Please enter a Display Name")
    .max(50, "Display Name is too long (max: 50 chars)"),
  location: y.string().max(50, "Location is too long (max: 50 chars)"),
  website: y.string().max(100, "Website is too long (max: 100 chars)"),
  bio: y.string(),
});

export const rule_schema = y.object().shape({
  id: y.string().max(100, "💯"),
  title: y
    .string()
    .max(250, "Rule titles must not exceed 250 characters")
    .required("Every rule must have a title"),
  html: y.string().max(2500, "Rule text must not exceed 2500 characters"),
});

export const security_schema = y.object().shape({
  max_individual_file_size: y
    .string()
    .stringMax(100, "Individual file size exceeded (Max: 100MB)")
    .required("Please enter a max individual file size")
    .stringWholeNumber("Must be a whole number"),
  max_emission_chars: y
    .string()
    .stringMax(10000, "Max emission chars exceeded: (Max: 10,000)")
    .required("Please enter a max emission character count")
    .stringWholeNumber("Must be a whole number"),
  max_file_count: y
    .string()
    .stringMax(10, "Max file count exceeded (Max: 10)")
    .required("Please enter a max file count")
    .stringWholeNumber("Must be a whole number"),
  max_total_file_size: y
    .string()
    .stringMax(100, "Max total file size exceeded (Max: 100MB)")
    .required("Please enter a max total file size")
    .stringWholeNumber("Must be a whole number"),
  gigachad: gigachad_schema,
  audioAllowed: y.boolean().required("audioAllowed is required"),
  email_verification_required: y
    .boolean()
    .required("email_verification_required is required"),
  imagesAllowed: y.boolean().required("imagesAllowed is required"),
  liveStreamingAllowed: y
    .boolean()
    .required("liveStreamingAllowed is required"),
  require_approval: y.boolean().required("require_approval is required"),
  streaming_enabled: y.boolean().required("streaming_enabled is required"),
  streaming_storage_enabled: y
    .boolean()
    .required("streaming_storage_enabled is required"),
  streaming_verification_required: y
    .boolean()
    .required("streaming_verification_required is required"),
  videoAllowed: y.boolean().required("videoAllowed is required"),
  rules: y.array().of(rule_schema),
});

export const preference_schema = name_icon_schema
  .concat(verbiage_schema)
  .concat(appearance_schema)
  .concat(security_schema);

export const update_preference_schema = y.object().shape({
  app_name: y
    .string()
    .min(1, "Please enter an App Name")
    .max(20, "App name is too long (Max: 20 chars)")
    .required("Please enter an app name"),
  description: y
    .string()
    .min(1, "Please enter a description")
    .max(150, "Description is too long (Max: 150 chars)")
    .required("Please enter a description"),
  max_individual_file_size: y
    .string()
    .stringMax(100, "Individual file size exceeded (Max: 100MB)")
    .required("Please enter a max individual file size")
    .required("Please enter a max individual file size")
    .stringWholeNumber("Must be a whole number"),
  max_emission_chars: y
    .string()
    .stringMax(10000, "Max emission chars exceeded: (Max: 10,000)")
    .required("Please enter a max emission character count")
    .stringWholeNumber("Must be a whole number"),
  max_file_count: y
    .string()
    .stringMax(10, "Max file count exceeded (Max: 10)")
    .required("Please enter a max file count")
    .stringWholeNumber("Must be a whole number"),
  max_total_file_size: y
    .string()
    .stringMax(100, "Max total file size exceeded (Max: 100MB)")
    .required("Please enter a max total file size")
    .stringWholeNumber("Must be a whole number"),
  emission_name: y
    .string()
    .min(1, "Please enter an emission name")
    .max(30, "Emission name is too long (Max: 30 chars)")
    .required("Please enter an emission name"),
  emission_plural: y
    .string()
    .min(1, "Please enter a plural emission name")
    .max(30, "Emission plural name is too long (Max: 30 chars)")
    .required("Please enter a plural emission name"),
  signalboost_name: y
    .string()
    .min(1, "Please enter a signalboost name")
    .max(30, "Signalboost name is too long (Max: 30 chars)")
    .required("Please enter a signalboost name"),
  signalboost_plural: y
    .string()
    .min(1, "Please enter a plural signalboost name")
    .max(30, "Plural signalboost name is too long (Max: 30 chars)")
    .required("Please enter a plural signalboost name"),
  signalboost_past: y
    .string()
    .min(1, "Please enter a past signalboost name")
    .max(30, "Past signalboost name is too long (Max: 30 chars)")
    .required("Please enter a past signalboost name"),
  signalboost_verb: y
    .string()
    .min(1, "Please enter signalboost verb")
    .max(30, "Signalboost verb is too long (Max: 30 chars)")
    .required("Please enter signalboost verb"),
  signalboost_current: y
    .string()
    .min(1, "Please enter signalboost current")
    .max(30, "Signalboost current is too long (Max: 30 chars)")
    .required("Please enter signalboost current"),
  admin_name: y
    .string()
    .min(1, "Please enter an admin name")
    .max(30, "Admin name is too long (Max: 30 chars)")
    .required("Please enter an admin name"),
  admin_plural: y
    .string()
    .min(1, "Please enter an admin plural name")
    .max(30, "Admin Plural name is too long (Max: 30 chars)")
    .required("Please enter an admin plural name"),
  mod_name: y
    .string()
    .min(1, "Please enter a moderator name")
    .max(30, "Moderator name is too long (Max: 30 chars)")
    .required("Please enter a moderator name"),
  mod_plural: y
    .string()
    .min(1, "Please enter a plural moderator name")
    .max(30, "Plural moderator name is too long (Max: 30 chars)")
    .required("Please enter a plural moderator name"),
  child_name: y
    .string()
    .min(1, "Please enter a regular user name")
    .max(30, "Regular user name is too long (Max: 30 chars)")
    .required("Please enter a regular user name"),
  child_plural: y
    .string()
    .min(1, "Please enter a plural regular user name")
    .max(30, "Plural regular user name is too long (Max: 30 chars)")
    .required("Please enter a plural regular user name"),
  custom_css: y
    .string()
    .minifiedMax(100000, "Custom CSS is too long (max: 100,000 chars)"),
});

export const bug_schema = y.object().shape({
  app: y.string().required("lol"),
  name: y
    .string()
    .min(1, "Please enter your name")
    .max(100, "Name is too long (Max: 100 chars)")
    .required("Please enter your name"),
  subject: y
    .string()
    .min(1, "Please enter a subject")
    .max(100, "Subject is too long (Max: 100 chars)")
    .required("Please enter a subject"),
  feedback: y
    .string()
    .min(1, "Please enter your feedback")
    .max(10000, "Feedback is too long (Max: 10000 chars)")
    .required("Please enter your feedback"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
});

export const request_schema = y.object().shape({
  app: y.string().required("lol"),
  name: y
    .string()
    .min(1, "Please enter your name")
    .max(100, "Name is too long (Max: 100 chars)")
    .required("Please enter your name"),
  subject: y
    .string()
    .min(1, "Please enter a subject")
    .max(100, "Subject is too long (Max: 100 chars)")
    .required("Please enter a subject"),
  feedback: y
    .string()
    .min(1, "Please enter your feedback")
    .max(10000, "Feedback is too long (Max: 10000 chars)")
    .required("Please enter your feedback"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
});

export const report_abuse_schema = y.object().shape({
  nature: y.string().required("lol"),
  link: y
    .string()
    .max(1000, "Link is too long (Max: 1000 chars)")
    .required("Please enter a link"),
  feedback: y
    .string()
    .min(1, "Please enter your feedback")
    .max(10000, "Feedback is too long (Max: 10000 chars)")
    .required("Please enter your feedback"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
});

export const request_access_jizzer_schema = y.object().shape({
  about: y
    .string()
    .max(5000, "Too long (Max: 5000 chars)")
    .required("Please fill out all the fields"),
  plan: y
    .string()
    .max(5000, "Too long (Max: 5000 chars)")
    .required("Please fill out all the fields"),
});

export const edit_user_schema = y.object().shape({
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  displayName: y
    .string()
    .min(1, "Please enter a Display Name")
    .max(50, "Display Name is too long (max: 50 chars)"),
  location: y.string().max(50, "Location is too long (max: 50 chars)"),
  website: y.string().max(100, "Website is too long (max: 100 chars)"),
  // bio: y.string().max(1000, 'Bio is too long (Max: 1000 chars)')
});

export const edit_user_mod_schema = y.object().shape({
  displayName: y
    .string()
    .min(1, "Please enter a Display Name")
    .max(50, "Display Name is too long (max: 50 chars)"),
  location: y.string().max(50, "Location is too long (max: 50 chars)"),
  website: y.string().max(100, "Website is too long (max: 100 chars)"),
});

export const poll_schema = y.object().shape({
  question: y
    .string()
    .max(120, "Question is too long (max: 120 characters)")
    .required("Please enter a question"),
  votesAllowed: y
    .number("Votes allowed must be numeric")
    .required("Please indicate the number of votes allowed"),
});

export const option_schema = y.object().shape({
  option: y
    .string()
    .max(120, "Option is too long (max: 120 characters)")
    .required("Please enter an option"),
});

export const edit_chadmin_schema = y.object().shape({
  username: y
    .string()
    .min(4, "Username is too short (min: 4 characters)")
    .max(30, "Username is too long (max: 30 characters)")
    .required("Please enter a username")
    .matches(/^[a-zA-Z0-9-_]+$/, "Username must be alphanumeric"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  comments: y.string().max(500, "Comments are too long (max: 500 characters)"),
});

export const user_schema_chadmin = y.object().shape({
  username: y
    .string()
    .min(4, "Username is too short (min: 4 characters)")
    .max(30, "Username is too long (max: 30 characters)")
    .required("Please enter a username")
    .matches(/^[a-zA-Z0-9-_]+$/, "Username must be alphanumeric"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  comments: y.string().max(500, "Comments are too long (max: 500 characters)"),
});

export const edit_janny_schema = y.object().shape({
  username: y
    .string()
    .min(4, "Username is too short (min: 4 characters)")
    .max(30, "Username is too long (max: 30 characters)")
    .required("Please enter a username")
    .matches(/^[a-zA-Z0-9-_]+$/, "Username must be alphanumeric"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  comments: y.string().max(500, "Comments are too long (max: 500 characters)"),
});

export const user_schema_janny = y.object().shape({
  username: y
    .string()
    .min(4, "Username is too short (min: 4 characters)")
    .max(30, "Username is too long (max: 30 characters)")
    .required("Please enter a username")
    .matches(/^[a-zA-Z0-9-_]+$/, "Username must be alphanumeric"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  comments: y.string().max(500, "Comments are too long (max: 500 characters)"),
});

export const edit_verified_schema = y.object().shape({
  username: y
    .string()
    .min(4, "Username is too short (min: 4 characters)")
    .max(30, "Username is too long (max: 30 characters)")
    .required("Please enter a username")
    .matches(/^[a-zA-Z0-9-_]+$/, "Username must be alphanumeric"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  comments: y.string().max(500, "Comments are too long (max: 500 characters)"),
});

export const user_schema_verified = y.object().shape({
  username: y
    .string()
    .min(4, "Username is too short (min: 4 characters)")
    .max(30, "Username is too long (max: 30 characters)")
    .required("Please enter a username")
    .matches(/^[a-zA-Z0-9-_]+$/, "Username must be alphanumeric"),
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  comments: y.string().max(500, "Comments are too long (max: 500 characters)"),
});

export const change_password_schema = y.object().shape({
  current_password: y.string().required("Please enter your password"),
  password1: y
    .string()
    .min(4, "Password is too short (min: 4 characters)")
    .max(256, "Password is too long (max: 256 characters)")
    .required("Please enter a new password"),
  password2: y
    .string()
    .oneOf([y.ref("password1"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const change_password_uuid_schema = y.object().shape({
  password1: y
    .string()
    .min(4, "Password is too short (min: 4 characters)")
    .max(256, "Password is too long (max: 256 characters)")
    .required("Please enter a new password"),
  password2: y
    .string()
    .oneOf([y.ref("password1"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const forgot_password_schema = y.object().shape({
  username: y.string().required("Please enter your username"),
  email: y.string().required("Please enter your email address"),
});

export const user_update_schema = y.object().shape({
  email: y
    .string()
    .min(4, "Email is too short (min: 4 characters)")
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  bio: y.string().max(1000, "Bio is too long (Max: 1000 chars)"),
});

export const login_schema = y.object().shape({
  username: y.string().required("Please enter a username"),
  password: y.string().required("Please enter a password"),
});

export const contact_form_schema = y.object().shape({
  email: y
    .string()
    .max(256, "Email is too long (max: 256 characters)")
    .email("Invalid email")
    .required("Please enter an email")
    .noPluses("Invalid characters in email"),
  name: y
    .string()
    .max(50, "Name is too long (max: 50 characters)")
    .required("Please enter your name"),
  subject: y
    .string()
    .max(150, "Subject is too long (max: 150 characters)")
    .required("Please enter a subject"),
  message: y
    .string()
    .max(10000, "Message is too long (max: 10000 characters)")
    .required("Please enter a message"),
});

export const image_schema = y.object().shape({
  name: y.string().max(50, "Name is too long (max: 50 characters)"),
  manifesto: y
    .string()
    .max(10000, "Manifesto is too long (max: 10000 characters)"),
});

export const comment_schema = y.object().shape({
  name: y.string().max(50, "Name is too long (max: 50 characters)"),
  body: y
    .string()
    .max(10000, "Body is too long (max: 10000 characters)")
    .required("Please enter a message")
    .checkWhiteSpace("Please enter a message"),
});

export const report_schema = y.object().shape({
  details: y
    .string()
    .max(200, "Report reason is too long (max: 200 characters)")
    .required("Please enter a reason")
    .checkWhiteSpace("Please enter a reason"),
});
