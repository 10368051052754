import React from "react";
import Preferences from "./jizzer/Preferences";
import LaunchStatus from "./jizzer/LaunchStatus";

class JizzerView extends React.Component {
  constructor(props) {
    super();
    this.view = props.view;
  }

  render() {
    switch (this.view) {
      case "prelaunch":
        return <Preferences {...this.props} />;
      case "launching":
        return <LaunchStatus {...this.props} />;
      case "live":
        return <>Launch</>;
      default:
        console.log("oob jizzer view", this.view);
        return <></>;
    }
  }
}

export default JizzerView;
